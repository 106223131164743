import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

export default function Navbar() {
    return (
        <NavBarContainer>
            <ContactButton>Logo</ContactButton>
            <NavLinks>
                <li>
                    <StyledLink to='/' >HOME</StyledLink>
                </li>
                <li>
                    <StyledLink to='/blogs' >BLOGS</StyledLink>
                </li>
                <ContactButton>CONTACT</ContactButton>
            </NavLinks>




        </NavBarContainer>
    )
}

const NavStyle = css`
    color: white;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

`

const NavBarContainer = styled.ul`
    display: flex;
    list-style: none;
    justify-content:space-between;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0;
`
const NavLinks = styled.li`
    display: flex;
    ${'li'} {
        margin: 0 20px;
    }
`
const StyledLink = styled(Link)`
    ${NavStyle}
`

const ContactButton = styled.li`
    ${NavStyle}
`