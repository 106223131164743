import CTA from "./CTA";
import Social from "./Social";


export default function Footer() {
    return (
        <div>
            <CTA />
            <Social />
        </div>
    )
}
