import React from 'react'
import Firstfold from '../components/Firstfold'

export default function Blogs() {
    return (
        <>
            <Firstfold />
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
            <h2>Blogs</h2>
        </>
    )
}
