import styled from 'styled-components'
export default function Firstfold() {

    return (
        <Container>
            FirstFold
        </Container>
    )
}

const Container = styled.div`
    background: blue;
    height: 100%;
    position: relative;
    top: -62px;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`
