import { useState } from 'react';
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme';
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import DefaultLayout from './layout/DefaultLayout';
import Blogs from './pages/Blogs';

function App() {
  const [isDarkMode] = useState(false)
  return (
    <ThemeProvider theme={isDarkMode ? theme.dark : theme.light}>
      <DefaultLayout>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/blogs' component={Blogs} />
        </Switch>
      </DefaultLayout>
    </ThemeProvider>
  );
}

export default App;
//test deploy


