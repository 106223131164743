import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styled from 'styled-components'

export default function DefaultLayout({ children }) {
    return (
        <Container>
            <Navbar />
            {children}
            <Footer />
        </Container>
    )
}

const Container = styled.div`
  height: 100%;
  text-align: center;
`